const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    componentPath:'/Home/index.tsx'
  },{
    path: ["/login"],
    exact: true,
    component: "LoginPage",
    componentPath:'/Login/index.jsx'
  },
  {
    path: ["/admin/dashboard"],
    exact: true,
    component: "Dashboard",
    componentPath:'/Admin/index.js'
  },
];

export default routes;
